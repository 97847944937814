import { StorageSerializers } from '@vueuse/core'

export default async (url, requiredPendingStatus = false, options = {} ) => {
    // Use sessionStorage to cache the Apim data data
    const cached = useSessionStorage(url, null, {
        // By passing null as default it can't automatically
        // determine which serializer to use
        serializer: StorageSerializers.object
    });

    const isPending = ref(true);

    if (!cached.value) {
        const { data, error, pending } = await useFetch(url, options);

        if (error.value) {
            console.warn(error.value)

            throw createError({
                ...error.value,
                statusMessage: `Could not fetch data from ${url}`,
                fatal: true
            })
        }

        isPending.value = pending.value;
        cached.value = data.value;
    } else {
        isPending.value = false
    }

    if (requiredPendingStatus) {
        return {
            cached,
            isPending
        };
    } else {
        return cached
    }
};
